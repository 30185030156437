import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import api from './api'
import i18n from './plugins/i18n'
import './plugins/vee-validate'

Vue.config.productionTip = false
Vue.prototype.$api = api

new Vue({
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
