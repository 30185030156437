<template>
  <v-container
    fluid
    class="pa-0"
  >
    <section id="hero">
      <v-row no-gutters>
        <v-img
          :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
          src="https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80"
        >
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col
                  class="white--text text-center"
                  cols="12"
                  tag="h1"
                >
                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2',
                    ]"
                    class="font-weight-light"
                  >
                    SOMOS A
                  </span>

                  <br />

                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
                    ]"
                    class="font-weight-black"
                  >
                    INGACODE
                  </span>
                </v-col>

                <v-btn
                  class="align-self-end"
                  fab
                  outlined
                  @click="$vuetify.goTo('#about-me')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>

    <section id="about-me">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">QUEM SOMOS</h2>

        <v-responsive
          class="mx-auto mb-8"
          width="56"
        >
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive
          class="mx-auto title font-weight-light mb-8"
          max-width="720"
        >
          Somos uma empresa formada por pessoas motivadas por qualidade no trabalho que fazem.
          Nossa missão é entregar a melhor solução para o seu negócio e de maneira ágil e eficiente buscamos conciliar tudo que tange à arquitetura de soluções para o desenvolvimento de aplicações de alta performance.
          Para isso, contamos com uma equipe especializada e qualificada nas principais tecnologias do mercado.
          Temos em nossa equipe profissionais certificados e preparados para te ajudar na concepção de produtos, desenvolvimento e sustentação de aplicações, consultoria técnica e validação de ideias.
          Queremos ser a empresa parceira que vai te ajudar a alavancar seu negócio e criar novas oportunidades para o futuro.
        </v-responsive>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section
      id="features"
      class="grey lighten-3"
    >
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">SERVIÇOS</h2>

        <v-responsive
          class="mx-auto mb-12"
          width="56"
        >
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ icon, title, text }, i) in features"
            :key="i"
            cols="12"
            xl="3"
            lg="6"
          >
            <v-card
              class="py-12 px-4 service"
              color="grey lighten-5"
              flat
            >
              <v-theme-provider dark>
                <div>
                  <v-avatar
                    color="primary"
                    size="88"
                  >
                    <v-icon
                      large
                      v-text="icon"
                    ></v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title
                class="justify-center font-weight-black text-uppercase"
                v-text="title"
              ></v-card-title>

              <v-card-text
                class="subtitle-1"
                v-text="text"
              > </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <v-sheet
      id="contact"
      color="#333333"
      dark
      tag="section"
      tile
    >
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          Entre em contato
        </h2>

        <v-responsive
          class="mx-auto mb-12"
          width="56"
        >
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row justify="center">
          <v-col
            cols="12"
            xl="8"
            lg="8"
          >
            <v-theme-provider light>
              <validation-observer ref="formContact">
                <v-form>
                  <v-row>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="name"
                        rules="required"
                      >
                        <v-text-field
                          v-model="model.name"
                          label="Nome*"
                          filled
                          background-color="white"
                          required
                          :counter="100"
                          :error-messages="errors"
                          :success="valid"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="sender"
                        rules="required|email"
                      >
                        <v-text-field
                          v-model="model.sender"
                          label="Email*"
                          filled
                          background-color="white"
                          required
                          :counter="100"
                          :error-messages="errors"
                          :success="valid"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="subject"
                        rules="required"
                      >
                        <v-text-field
                          v-model="model.subject"
                          label="Assunto*"
                          filled
                          background-color="white"
                          required
                          :counter="250"
                          :error-messages="errors"
                          :success="valid"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="message"
                        rules="required"
                      >
                        <v-textarea
                          v-model="model.message"
                          label="Mensagem*"
                          filled
                          background-color="white"
                          required
                          :counter="1000"
                          :error-messages="errors"
                          :success="valid"
                        ></v-textarea>
                      </validation-provider>
                    </v-col>

                    <v-col
                      class="mx-auto"
                      cols="auto"
                    >
                      <v-btn
                        color="accent"
                        x-large
                        @click="sendMail"
                        :loading="buttonLoading"
                      >
                        Enviar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </validation-observer>
            </v-theme-provider>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "BaseContent",

  data: () => ({
    model: {
      name: "",
      sender: "",
      subject: "",
      message: "",
    },
    buttonLoading: false,
    features: [
      {
        icon: "mdi-account-group-outline",
        title: "Soluções em desenvolvimento",
        text: "Desenvolvimento de software sob medida para sua empresa. " +
          "Elaboramos todo o processo de arquitetura, concepção e desenvolvimento da solução para " +
          "mobile, web ou desktop. Realizando entregas rápidas e funcionais de maneira ágil, a melhor solução para sua necessidade. " +
          "Caso possua uma aplicação e necessite de novas funcionalidades ou sustentação, " +
          "nossas equipes multidisciplinares são preparadas para manter e evoluir o seu produto.",
      },
      {
        icon: "mdi-update",
        title: "Consultoria",
        text: "Nossa equipe de consultoria é especializada na solução de problemas, de maneira eficiente e simples, " +
          "vamos ajudá-lo a melhorar e evoluir seu produto. " +
          "Trabalhando juntos, vamos conceder todo o direcionamento tecnológico necessário para concepção do produto, " +
          "seja para uma definição de equipe técnica, otimização de processos internos, criando soluções e crescendo de maneira eficiente com qualidade.",
      },
      {
        icon: "mdi-shield-outline",
        title: "Product Discovery",
        text: "Desenvolver um novo produto pode ser um enorme desafio, a otimização de recursos é essencial. " +
          "Nossa equipe de \"Product Discovery\" irá fornecer o suporte necessário em todo processo de arquitetura de soluções, " +
          "análises técnicas e usabilidade. Proporcionando segurança na tomada de decisão, " +
          "com o melhor direcionamento no desenvolvimento da aplicação, podendo otimizar tempo e recursos financeiros.",
      },
      {
        icon: "mdi-cog-play",
        title: "Startups projects",
        text: "Somos apaixonados por empreendedorismo! Somos movidos por essa força que acredita na " +
          "criação de soluções disruptivas que agregam valor para o mundo. " +
          "Juntos podemos evoluir, descobrir o melhor caminho, validar sua ideia e gerar valor para o mundo. " +
          "Entre em contato conosco, podemos te ajudar a tirar seu sonho do papel.",
      },
    ],
  }),

  methods: {
    async sendMail () {
      try {
        if (await this.$refs.formContact.validate()) {
          this.buttonLoading = true
          await this.$api.mail.sendMail(this.model);
          this.cleanFields();
          this.buttonLoading = false
        }
      } catch (err) {
        console.error(err);
        this.buttonLoading = false
      }
    },

    cleanFields () {
      this.model = {
        name: "",
        sender: "",
        subject: "",
        message: "",
      };
      this.$refs.formContact.reset()
    },
  },
};
</script>
<style lang="sass">
.v-counter
  color: rgba(255, 255, 255, 0.7) !important
.service
  min-height: 550px
</style>