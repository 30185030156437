<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      height="100"
    >
      <v-avatar
        class="mr-3"
        color="grey lighten-5"
        size="70"
      >
        <v-img
          contain
          max-height="70%"
          :src="require('./assets/logo.png')"
        ></v-img>
      </v-avatar>

      <v-toolbar-title class="font-weight-black headline">
        INGACODE
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text @click="$vuetify.goTo('#about-me')">
          Quem Somos
        </v-btn>

        <v-btn text @click="$vuetify.goTo('#features')">
          Serviços
        </v-btn>

        <v-btn text @click="$vuetify.goTo('#contact')">
          Contato
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <Content />
    </v-main>

    <v-footer
      class="justify-center"
      color="#292929"
      height="100"
    >
      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        &copy; 2020 — IngaCode
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import Content from "./components/Content";

export default {
  name: "App",

  components: {
    Content,
  },

  data: () => ({
    //
  }),
};
</script>
