import Vue from 'vue'
import {
    extend,
    ValidationObserver,
    ValidationProvider,
} from 'vee-validate'
import {
    email,
    required,
} from 'vee-validate/dist/rules'

extend('email', email)
extend('required', required)

Vue.component('validation-provider', ValidationProvider)

Vue.component('validation-observer', ValidationObserver)
